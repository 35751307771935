import React from 'react';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import { Navigate } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';

// ----------------------------------------------------------------------

AuthProtect.propTypes = {
	children: PropTypes.node,
};

function AuthProtect({ children }) {
	const { isLoading, isAuthenticated } = useAuth();
	if (isLoading) {
		return <LoadingScreen />;
	}

	if (!isAuthenticated) {
		return <Navigate to='/extracard' replace/>;
	}

	return <>{children}</>;
}

export default AuthProtect;
