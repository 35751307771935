import React, { useEffect, useRef, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import 'react-phone-number-input/style.css'
import { loadStripe } from "@stripe/stripe-js";
import './Payment.scss';
import Stripe from "../components/stripeElement/Stripe";
import PhoneInput from "react-phone-number-input";
import { styled } from '@mui/material/styles';
import { Paper, Popover,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, } from "@mui/material";
import { tableCellClasses } from '@mui/material/TableCell';
import axios from "axios";
import configData from '../config.json'
import { useSnackbar } from "notistack";
import { CircularProgress, Icon, IconButton } from "@mui/material";
import closeFill from "@iconify-icons/eva/close-fill";
import { useNavigate } from "react-router-dom";
import { _deleteSession } from "../redux/slices/authJwt";



const PaymentExtra =() => {
  const [email, setUserEmail] = useState("");
  const [phone, setUserPhone] = useState("");
  const [plan, setPlan] = useState('premium');

  const handleChangePlan = (event) => {
    setPlan(event.target.value);
    console.log(plan)
  };
  const [code, setCode] = useState("");
  const [showPaymentMenu, setShowPaymentMenu] = useState(false);
  const [showInfoMenu, setShowInfoMenu] = useState(true);
  const myRef = useRef(null)

  const [loading, setLoading] = useState(false);
  const [stripeObject, setStripeObject] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState("");
    const [amount, setAmount] = useState(149);
    const [disabled, setDisabled] = useState(true);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const onSubmit = async() => {
    setLoading(true)
   if(email && phone){
    try{
      const response = await axios.post(configData.SERVER_URL + configData.checkUser, {
        email: email,
        phone: phone
      });
      if(response && response.data.error === 0){
        setShowPaymentMenu(true); 
      }else if (response  && response.data.error !== 0) {
        enqueueSnackbar( response?.data?.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          variant: "error",
          action: (key) => (
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </IconButton>
          ),
        });
      } 
    }catch (err) {
      if(err?.request?.status === 477){
        _deleteSession();
        navigate('/login', { replace: true });
       }
    }
    
   }
   setLoading(false);
    }
    useEffect(() => {
      console.log("showPaymentMenu")
      executeScroll ()
    }, [showPaymentMenu]);
    const executeScroll = () => myRef.current.scrollIntoView()  

  //STRIPE PAYMENT

  useEffect(() => {
    document.body.style.overflow = 'unset';
    console.log("    test:",    )
    console.log("    process.env.REACT_APP_STRIPE_PUBLIC_KEY:",    process.env.REACT_APP_STRIPE_PUBLIC_KEY)
    const fetchStripeObject = async () => {
      const res = await loadStripe(
       "pk_live_51MP2MnHQKcZF7P8qPt3QJeYAANxpK2xthsQWCRIsTALEDRmw6wkzNsUxzqqvP0GDjrhAWzZNZ0rXh4omujpBrptm00ulv92uHU"
      );
      if (res) {
        setStripeObject(res);
      }
    };
    fetchStripeObject();
  }, [showPaymentMenu]);

  const handleChange = (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#070A29",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: "#191E4F",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  

  return (
    <>
            
    {(showInfoMenu && !succeeded) &&(
    <div className='wrapper'>

    <section className="touch">
                <div className="container">
                    <div className="row" style={{alignItems: 'center', justifyContent: 'center'}}>
                        <div className="info">
                            <div className="block-text center">
                                <h6 className="sub-heading"><span>Informations</span></h6>
                            </div>
                            <div className="touch__main">

                                <form action="#" className="form-box">
                                    <div className="row">
                                        <div className="col">
                                            <label >Your email</label>
                                            <input className="form-control" type="email" onChange={(e)=> {setUserEmail(e.target.value)}}  placeholder="example@example.com" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <label >Your phone number</label>
                                            <PhoneInput
                                                className="form-control telInput"
                                                placeholder="Enter phone number"
                                                value={phone}
                                                defaultCountry="FR"
                                                onChange={setUserPhone}
                                                style={{backgroundColor: "transparent"}}/>
                                            {/* <input className="form-control" type="tel" onChange={(e)=> {setUserPhone(e.target.value)}} maxLength="20"  pattern="[0-9]{10}" /> */}
                                        </div>

                                    </div>
                                    <div className="row">
                                      <div className="col" style={{display:'flex', flexDirection: 'column'}}>
                                      <label onClick={handleClick}>Which plan? (Click here to see more)</label>
                                      <Popover
                                          id={id}
                                          open={open}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          }}
                                          transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          }}
                                        >
                                          <Box style={{padding:5}}>
                                          <TableContainer component={Paper}>
                                              <Table>
                                                <TableHead>
                                                  <TableRow >
                                                    <StyledTableCell><h5 style={{fontSize: 14}}>Premium</h5></StyledTableCell>
                                                    <StyledTableCell><h5 style={{fontSize: 14}}>Ultimate</h5></StyledTableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>                                                 
                                                    <StyledTableRow >
                                                      <TableCell><h6>Play safe</h6></TableCell>
                                                      <TableCell><h6>All-in</h6></TableCell>
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                      <TableCell><h6>149€ /year</h6></TableCell>
                                                      <TableCell><h6>299€ /year</h6></TableCell>
                                                    </StyledTableRow>
                                                    <StyledTableRow >
                                                      <TableCell><h6>1 NFT guaranteed /year</h6></TableCell>
                                                      <TableCell><h6>3 NFTs guaranteed /year</h6></TableCell>
                                                    </StyledTableRow>
                                                    <StyledTableRow >
                                                      <TableCell><h6>20 Lottery Tickets /month</h6></TableCell>
                                                      <TableCell><h6>50 Lottery Tickets /month</h6></TableCell>
                                                    </StyledTableRow>
                                                    <StyledTableRow >
                                                      <TableCell><h6>No Booster</h6></TableCell>
                                                      <TableCell><h6>1 Booster /month</h6></TableCell>
                                                    </StyledTableRow>
                                                    <StyledTableRow >
                                                      <TableCell><h6>IBAN + Visa debit card</h6></TableCell>
                                                      <TableCell><h6>IBAN + Visa debit card</h6></TableCell>
                                                    </StyledTableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Box>
                                           
                                            
                                       </Popover>
                                        
                                   
                                        <select
                                        className="plan-select"
                                          value={plan}
                                          onChange={handleChangePlan}
                                        >
                                          <option value="premium">Premium (149€)</option>
                                          <option value="ultimate">Ultimate (299€)</option>
                                        </select>
                                        </div>
                                    </div>
                                    <div className="row mb-0">
                                        <div className="col">
                                             <button className="action-btn" onClick={onSubmit} disabled={loading} type="button" value="Submit"><span style={{width: 130}}>{loading ? <CircularProgress size={15}/> : <>{"Following"}</>}</span></button> 
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div ref={myRef} >
            {showPaymentMenu  &&(
                      <div className="payment-menu" >
                 {  plan === "premium" ? <h5 className="heading" style={{marginBottom: 50, fontSize: 30}}>Bank payment (149€)</h5> : <h5 className="heading" style={{marginBottom: 50, fontSize: 30}}>Bank payment (299€)</h5>}
              
                        {stripeObject && (
                                <Elements stripe={stripeObject}>    
                                  <Stripe email={email} phone={phone} plan={plan} code={code} handleChange={handleChange} setProcessing={setProcessing} error={error} setError={setError} succeeded={succeeded} setSucceeded={setSucceeded} amount={amount} setAmount={setAmount} processing={processing} disabled={disabled}/>
                                </Elements>
                              )}    
                            
                  </div>
                  )} 
            </div>
    
        
    </div>
    )}
        {
          succeeded && (
            <section className="touch">
                <div className="container">
                    <div className="row" style={{alignItems: 'center', justifyContent: 'center'}}>
                        <h1 style={{textAlign: 'center'}}>Thank you for your purchase !</h1>
                    </div>
                </div>
            </section>
          )

        }


    </>
  );
}

export default PaymentExtra;
