
import { React , useEffect} from 'react';
import AOS from 'aos';
import { Provider } from 'react-redux';
import JwtProvider from './components/Auth/JwtProvider';
import { store, persistor } from './redux/store';
// import { BrowserRouter as Router } from 'react-router-dom';
import { MoralisProvider } from 'react-moralis';
import Header from './components/header/Header';
import ScrollToTop from './ScrollToTop';
import NotistackProvider from './components/notistack/NotistackProvider';
import { PersistGate } from 'redux-persist/lib/integration/react';
import LoadingScreen from './components/LoadingScreen';
import { createBrowserHistory } from 'history';

import { Web3ReactProvider } from '@web3-react/core';
import { ethers } from 'ethers';
import { Buffer } from 'buffer';
import { createTheme, ThemeProvider } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import Timeout from './pages/Timeout';
import Extracard from './pages/Extracard';
import PaymentExtra from './pages/PaymentExtra';
import Game from './pages/Game';
import Login from './pages/login';
import Profile from './pages/Profile';
import Home01 from './pages/Home01';
import Nfts from './pages/Nfts';
import Games from './pages/Games';
import AuthProtect from './components/Auth/AuthProtect';

import TempTitle from './pages/TempTitle';

import Lottery from './pages/Lottery';

  
import packageJson from "../package.json";
import { getBuildDate } from "./utils/util";
import withClearCache from "./ClearCache";
// import Register from './pages/register';
import RewardFactory from './pages/RewardFactory';
import Footer from './components/footer/Footer';


import axios from "axios"

if(localStorage.getItem("accessToken")!==null && localStorage.getItem("accessToken") !==undefined){
  console.log("app accessToken:",localStorage.getItem("accessToken"))
  axios.defaults.headers.common["Authorization"]="Bearer "+localStorage.getItem("accessToken")
  if(localStorage.getItem("accessToken").split(" ")[0]!="Bearer"){
  //  axios.defaults.headers.common["Authorization"]="Bearer "+localStorage.getItem("accessToken")

  }
  else{
    axios.defaults.headers.common["Authorization"]=localStorage.getItem("accessToken")

  }

}


const ClearCacheComponent = withClearCache(MainApp);

window.Buffer = window.Buffer || Buffer;
const getLibrary = (provider) => {
    return new ethers.providers.Web3Provider(provider);
  }

  function App() {
    return <ClearCacheComponent />;
  }
  
function MainApp() {
  
    useEffect(() => {
        AOS.init({
          duration : 2000
        }); 
      }, []);

    //   const history = createBrowserHistory();
      const theme = createTheme({
        palette: {
            mode: 'dark',
            // primary: {
            //   main: "rgb(182, 150, 58)",
            // },
            // secondary: {
            //   main: 'rgb(227, 79, 113)',
            // },
          },
        typography: {
            fontFamily: ['Conthrax', 'sans-serif'],
            color: '#fff'
          },
      });
    return (
        <MoralisProvider
                    appId="kAEFmSKLQBkotwOPLp2jjygPZmwviIq7Bo83nRUA"
                    serverUrl="https://3rdelv5fhzos.usemoralis.com:2053/server"
                >

        <>
     

            <Web3ReactProvider getLibrary={getLibrary}>
                <Provider store={store}>
                    <PersistGate  loading={<LoadingScreen />} persistor={persistor}>
                        <NotistackProvider>
                        <ThemeProvider theme={theme}>
                            {/* <Router history={history}> */}
                                <JwtProvider>
                                    <ScrollToTop />
                                    <Header />
                                    <Routes>
                                        <Route exact path="/timeout" element={<Timeout />} />
                                        <Route exact path="/extracard"  element={<Extracard />}/>
                                        <Route exact path="/payment" element={<PaymentExtra />} />
                                        <Route exact path="/games" element={<AuthProtect><Games /></AuthProtect>} />
                                        <Route exact path="/game/:uuid" element={<AuthProtect><Game /></AuthProtect>} />
                                        <Route exact path="/login" element={<Login />} />
                                        {/* <Route exact path="/register" element={<Register />} /> */}
                                        <Route exact path="/shop"  element={<AuthProtect><Nfts /></AuthProtect>}/>
                                        <Route exact path="/profile" element={<AuthProtect><Profile /></AuthProtect>} />


                                        <Route exact path="/deposit" element={<AuthProtect><TempTitle /></AuthProtect>} />

                                        <Route exact path="/reward-point-factory" element={<AuthProtect><RewardFactory /></AuthProtect>} />


                                        <Route exact path="/lottery" element={<AuthProtect><Lottery /></AuthProtect>} />

                                        <Route exact path="/" element={<Home01  />} />
                                        {/* <Route path='*' element={<Page404 />} /> */}
                                    </Routes>
                                    <Footer />
                                </JwtProvider>
                            {/* </Router> */}
                            </ThemeProvider>
                        </NotistackProvider>
                    </PersistGate>
                </Provider>
            </Web3ReactProvider>
  <p>Build date: {getBuildDate(packageJson.buildDate)}</p>
   </>
            </MoralisProvider>

          

       
    );
}

export default App;
