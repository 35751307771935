const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
  
    },
 
    {
        id: 2,
        name: 'ExtraCard',
        links: '/extracard',
        
    },
    // {
    //     id: 3,
    //     name: 'Timeout',
    //     links: '/timeout',
        
    // },
    {
        id: 4,
        name: 'Games',
        links: '/games',
        
    },
    {
        id: 5,
        name: 'Shop',
        links: '/shop',
        
    },

  
    
{
 
        id: 6,
        name: 'Factory',
        links: '/reward-point-factory',
        
    },


    {
        id: 7,
        name: 'Lottery',
        links: '/lottery',
        
    },
    //   {

    //     id: 8,
    //     name: 'Profile',
    //     links: '#',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'My Profile',
    //             links: '/profile'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Deposit',
    //             links: '/deposit'
    //         },
    //     ]
    // },

]

export default menus;