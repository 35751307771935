import React , {useEffect,  useRef,  useState} from 'react';
import './styles.scss'
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import { finishGame, getGameDetails, getPosibilities, launchGame,clearGame, getMyNumbers } from '../../redux/slices/game';
import {  Box, Fade, Modal, Backdrop } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import testImg from '../../assets/images/test.png'
import * as PIXI from "pixi.js";
import axios from 'axios';
import configData from '../../config.json'

var reload = false

function Main() {
    const { uuid } = useParams();
    const navigate = useNavigate();
    const ref = useRef(null);
    const dispatch = useDispatch();
    const { user } = useAuth();
    const { posibilities , gameResult, endGame, gameDetails} = useSelector((state) => state.game);
    const [clicked, setCLicked] = useState(false);
    const [announcement, setAnnouncement] = useState('');
    const [clickedAnnouncement, setCLickedAnnouncement] = useState(false);

    const [apps, setApp] = useState(null);

    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {setOpen(true)};
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#070A29',
        borderRadius: 10,
        boxShadow: 24,
        p: 6,
      };
    // const [width, setWidth]   = useState(window.innerWidth);
   var _width = window.innerWidth
   var _height = window.innerHeight
    const updateDimensions = () => {
        _width = window.innerWidth;
        _height = window.innerHeight;
    }
    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);
 
    useEffect(() => {
        
        window.history.replaceState({}, document.title)
		if (!uuid) return;
 
            dispatch(getGameDetails(uuid));
            dispatch(clearGame())
	}, [uuid]); 

    // useEffect(() => {
    //     if(user.displayName!==null && gameDetails){
    //     }   
    //   }, [user, gameDetails]); 

     useEffect(() =>{
         if(endGame && gameDetails.uuid){
             document.body.style.overflow = 'unset';
             setAnnouncement(endGame.message)
             dispatch(getPosibilities(user.id, gameDetails?.uuid))
             dispatch(getMyNumbers())
         }
     },[ endGame, gameDetails])

    const onLaunchGame = () => {
        dispatch(launchGame(user.id, gameDetails?.uuid));
        setCLicked(true)
        setCLickedAnnouncement(true)
        setAnnouncement('')
        executeScroll ()
        document.body.style.overflow = 'hidden';
    }
    const reLaunchGame = () => {
        reload = true

        dispatch(launchGame(user.id, gameDetails?.uuid));
        setCLicked(true)
        setCLickedAnnouncement(true)
        executeScroll ()
        document.body.style.overflow = 'hidden';
    }
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState();
    const [minutes, setMinutes] = useState();
    const [seconds, setSeconds] = useState();

    useEffect(() => {
        if(hours === 0 && minutes === 0 && seconds === 0 ){
            dispatch(getPosibilities(user.id, gameDetails?.uuid))
        }
    },[hours, minutes, seconds])
    const getTime = () => {
        const time = Date.parse(posibilities.result?.date) - Date.now();
    
        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
      };
      useEffect(() => {
        const interval = setInterval(() => getTime(posibilities?.result?.date), 1000);
    
        return () => clearInterval(interval);
      }, [posibilities]);

      const handleGoBack = () => {
        navigate('/games')
      }

  

///////////
var isReached=false
useEffect(() => {

    if(gameResult?.result?.imgFullLocal!==undefined){
      
        const app = new PIXI.Application({
            width: _width > 620 ? 600 : 300,
            height: _width > 620 ? 600 : 300,
            backgroundColor: '#000'
        });
        PIXI.utils.clearTextureCache()
        PIXI.Assets.reset()
        const brush = new PIXI.Graphics()
        .beginFill(0xffffff)
        .drawCircle(0, 10, 10);

        const line = new PIXI.Graphics();           
        //PIXI.Assets.load(url , {crossOrigin: 'anonymous'});
  // PIXI.Assets.add('t1', `${process.env.PUBLIC_URL}/assets/game/${gameResult?.result?.imgFullLocal}`);
    PIXI.Assets.add('t1', `data:image/png;base64, ${gameResult?.result?.b64Full}`);
  
        PIXI.Assets.add('t2', `data:image/png;base64, ${gameResult?.result?.b64}`);

        PIXI.Assets.load(['t1', 't2']).then(setup);

        //console.log(process.env.PUBLIC_URL)


        setApp(app)


    
   
      function setup() {
            const graphics = new PIXI.Graphics();
            graphics.beginFill(0xF43300);
            graphics.drawRect(50, 250, 100, 100);
            graphics.endFill();

            const { width, height } = app.screen;
            const stageSize = { width, height };
        
            const background = Object.assign(PIXI.Sprite.from('t1'), stageSize);
            const imageToReveal = Object.assign(PIXI.Sprite.from('t2'), stageSize);
            const renderTexture = PIXI.RenderTexture.create(stageSize);
            
            const renderTextureSprite = new PIXI.Sprite(renderTexture);
    
            imageToReveal.mask = renderTextureSprite;
     

            app.stage.addChild(
                background,
                imageToReveal,
                renderTextureSprite,
            );
        
            app.stage.interactive = true;
            app.stage.hitArea = app.screen;
            app.stage
                .on('pointerdown', pointerDown)
                .on('pointerup', pointerUp)
                .on('pointerupoutside', pointerUp)
                .on('pointermove', pointerMove);
        
            let dragging = false;
            let lastDrawnPoint = null;
      
            function pointerMove({ global: { x, y } }) {
                if (dragging) {
                    brush.position.set(x, y);
                    app.renderer.render(brush, {
                        renderTexture,
                        clear: false,
                        skipUpdateTransform: false,
                    });
        
        
                    
                    var pixels = app.renderer.extract.pixels(renderTexture);
                    var count = 0;
                    for (var i = 0, len = pixels.length; i < len; i += 4) {
                        if (pixels[i] === 255) {
                            ++count;
                        }
                    }
        
                    var progress = (100 * count / (renderTexture.width * renderTexture.height)).toFixed(2);
                    
                    if(isReached==false && progress>=30){
                        isReached=true
                        
                        dispatch(finishGame( gameResult?.result?.uuid))
                        setCLicked(false);
                        setCLickedAnnouncement(false)
                        
                    }
                    
                    if (lastDrawnPoint) {
                        line
                            .clear()
                            .lineStyle({ width: 20, color: 0xffffff })
                            .moveTo(lastDrawnPoint.x, lastDrawnPoint.y)
                            .lineTo(x, y);
                            app.renderer.render(line, {
                            renderTexture,
                            clear: false,
                            skipUpdateTransform: false,
                        });
                    }
                    
                    lastDrawnPoint = lastDrawnPoint || new PIXI.Point();
                    lastDrawnPoint.set(x, y);
                }
            }
        
            function pointerDown(event) {
                dragging = true;
                pointerMove(event);
            }
        
            function pointerUp(event) {
                dragging = false;
                lastDrawnPoint = null;
            }
        }


      ref.current.appendChild(app.view);
      app.start();
  
      return () => {
        ref?.current?.removeChild(app.view)
        app.stop();
     
        setApp(null)
      };
    
    }
 
}, [gameResult,reload, ]);


    ////////////////////////////////////
    useEffect(() => {
           
      
      }, [gameResult]);
    useEffect(() => {
  if(gameResult?.result?.imgFull!==undefined){
        
      
    }
  
  }, [gameResult, reload]);
   
  const executeScroll = () => ref.current.scrollIntoView({
    behavior: "smooth",
   block:  "center" ,
   inline: "start",
   top:25
  
  }) 
  /////////////////////////////////    
  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches,
          [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait'),
          onWindowResize = () => {              
            clearTimeout(window.resizeLag)
            window.resizeLag = setTimeout(() => {
              delete window.resizeLag                       
              setOrientation(isLandscape() ? 'landscape' : 'portrait')
            }, 200)
          }

    useEffect(() => (
      onWindowResize(),
      window.addEventListener('resize', onWindowResize),
      () => window.removeEventListener('resize', onWindowResize)
    ),[])
  
    return (
        <section className="token" >
                   <div className="shape"></div>
                
                    <div className="container">
                        <div className='game-container'>

                        <div className='left'>
                        <div className="token__main" data-aos="fade-down" data-aos-duration="2000">
                            <ul className="token-list">
                                <li>
                                    <div className="name">
                                    <Icon icon="eva:star-outline" />
                                    <p>0 numbers</p></div>
                                    <h6>1 point</h6>
                                </li>
                                <li>
                                    <div className="name">
                                    <Icon icon="eva:star-outline" />
                                    <p>1 good numbers</p></div>
                                    <h6>10 points</h6>
                                </li>
                                <li>
                                    <div className="name">
                                    <Icon icon="eva:star-outline" />
                                    <p>2 good numbers</p></div>
                                    <h6>50 points</h6>
                                </li>
                                <li>
                                    <div className="name">
                                    <Icon icon="eva:star-outline" />
                                    <p>3 good numbers</p></div>
                                    <h6>500 points</h6>
                                </li>
                            </ul>
                        </div>
                    </div>

                     {/* <h6 style={{textAlign: 'center', marginBottom: 20, cursor: 'pointer'}} onClick={handleOpen}>What can you win? (Click here to see)</h6> */}

                        {(orientation === 'landscape' && _height <= 620) ? <h6>It's better to use it in portrait mode, please rotate !</h6> :
                            <div className="right" style={{ position:"relative", display:'flex',flexDirection: 'column', alignItems: 'center', justifyContent:'center'}} ref={ref}>

                                <div className="about__main center" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <div style={{overflowX: 'scroll'}}>
                                      {  !gameResult.result &&
                                        
                                        <div className='frontImg-container'>
                                                <img src={ gameDetails?.imgFull } alt="" className='frontImg' />

                                               
                                        </div>}
                                       
                                     

                                               
                                        
                                    </div>
                              <div className='btnContainer'>
                              { (endGame.message  && posibilities.error !== 0) ?
                                                <button onClick={handleGoBack} className='playButton' style={{position: 'relative', top: 0,}}>{endGame.message && <p style={{textTransform: 'uppercase'}}>{endGame.message}!!!</p>}<span>{posibilities.message}</span>{posibilities.result?.date && <span>Next in {hours}h {minutes}min {seconds}s</span>}</button> 
                                                    : 
                                                    (endGame.message  && posibilities.error === 0) ?
                                                <Fade in={clickedAnnouncement === false} timeout={2000} style={{position: 'relative', top: 0,}}>
                                                    <button onClick={reLaunchGame} className='playButton' ><p style={{textTransform: 'uppercase'}}>{endGame.message}!!!</p><span>{posibilities.message}</span></button>
                                                </Fade> : (endGame.message && posibilities.error === 1 && posibilities.code === 3) ? 
                                                 <button onClick={handleGoBack} className='playButton' style={{position: 'relative', top: 0,}}>{endGame.message && <p style={{textTransform: 'uppercase'}}>{endGame.message}!!!</p>}<span>{posibilities.message}</span>{posibilities.result?.date && <span>Next in {hours}h {minutes}min {seconds}s</span>}</button>: ""
                                                }
                                             
                                                {(!announcement && posibilities.error !== 0 ) ?
                                                    <button onClick={handleGoBack} className='playButton' ><span>{posibilities.message}</span>{posibilities.result?.date && <span>Next in {hours}h {minutes}min {seconds}s</span>}</button> :
                                                   ( !announcement && posibilities.error === 0 )?
                                                    <Fade in={clicked === false} timeout={2000}>
                                                        <button onClick={onLaunchGame} className='playButton' ><p>PLAY</p><span>{posibilities.message}</span></button>
                                                    </Fade> :
                                                        (!announcement && posibilities.error === 1 && posibilities.code === 3) ? 
                                                        <button onClick={handleGoBack} className='playButton' ><span>{posibilities.message}</span>{posibilities.result?.date && <span>Next in {hours}h {minutes}min {seconds}s</span>}</button>
                                                  : '' }
                              </div>
                                </div>
                               
                            </div>}
                       </div>
                    </div>
                    {/* <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                        timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                        <Box sx={style} className='itemModal'>
                     
                        <div className="token__main">
                            <ul className="token-list">
                                <li>
                                    <div className="name">
                                    <Icon icon="eva:star-outline" />
                                    <p style={{marginRight: 20}}>0 winning number</p></div>
                                    <h6>1 point</h6>
                                </li>
                                <li>
                                    <div className="name">
                                    <Icon icon="eva:star-outline" />
                                    <p style={{marginRight: 20}}>1 winning number</p></div>
                                    <h6>10 points</h6>
                                </li>
                                <li>
                                    <div className="name">
                                    <Icon icon="eva:star-outline" />
                                    <p style={{marginRight: 20}}>2 winning numbers</p></div>
                                    <h6>50 points</h6>
                                </li>
                                <li>
                                    <div className="name">
                                    <Icon icon="eva:star-outline" />
                                    <p style={{marginRight: 20}}>3 winning numbers</p></div>
                                    <h6>500 points</h6>
                                </li>
                            </ul>
                        </div>
                  
                        </Box>
                         </Fade>                                             
                    </Modal>       */}
                   
                </section>
    );
}

export default Main;