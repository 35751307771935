import React, { useState } from 'react';
import images from "../Images.json"
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dataTeam from '../assets/fake-data/dataTeam';
import Banner from '../components/banner/Banner';
import Footer from '../components/footer/Footer';
import Team from '../components/team/Team';
import Collection from '../components/Collection';
import Testimonials from './Testimonials';
import dataTestimonials from '../assets/fake-data/data-testimonials';
import About from './About';
import Contact from './Contact';


function Home01(props) {
    const [categoryImage, setCategoryImage] = useState(images.categories.all)
    return (
        <div className="home-1 wrapper">
            
            <Banner />
            <About />
{/*            
            <Testimonials data={dataTestimonials} />
            <Contact /> */}
            <Collection data={categoryImage} />
        </div>
        

    );
}

export default Home01;