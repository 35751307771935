import React , {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import configData from '../../config.json';
import axios from 'axios'
import img1 from '../../assets/images/dummy_image/1.jpg'
import img2 from '../../assets/images/dummy_image/9.jpg'
import nft from '../../assets/images/nft.png'

import avt from '../../assets/images/layouts/avt-05.png'
import "./styles.scss"
import { ArrowRight } from 'iconsax-react';
import images from "../../Images.json"
import Collection from '../Collection';
import {useSearchParams} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import useAuth from '../../hooks/useAuth';
import { _deleteSession,login} from '../../redux/slices/authJwt';

function Main() {
    const [tribCode, setTribCode] = useState('')
    const [affiliCode, setAffiliCode] = useState('')
    const [errorTribeMessage, setErrorTribeMessage] = useState('');
    const [errorAffMessage, setErrorAffMessage] = useState('');
    const dispatch = useDispatch();
    const [categoryImage, setCategoryImage] = useState(images.categories.all)
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    useEffect(() => {
        document.body.style.overflow = 'unset';
        var tt=searchParams.get('ssoToken');
        console.log("tt",tt)
        if(tt!==null && tt!==undefined){
            _deleteSession()
            dispatch(login({tt})) 
        //     axios.post(configData.SERVER_URL + "/game/init",{
        //         ssoToken:tt
        //     })
        //     .then(function (response) {
        //       console.log("response",response)
        //    //   setSessus(response.data)
    
        // })
        }
    
    },[])

    const handleSubmitCode = (e) => {
        if(tribCode){
            axios.post(configData.SERVER_URL + configData.checkTrib,
            {
                tribCode: tribCode
            })
                .then(function (response) {
                    if (response.data.error === 0) {
                        localStorage.setItem("tribe", tribCode);
                        // localStorage.removeItem("aff");
                        localStorage.setItem("pageType", "extracard");
                        navigate('/payment');
                    }else{
                        setErrorTribeMessage(response.data.message);
                    }
            })
        }
        if(affiliCode){
            console.log(affiliCode)
            axios.post(configData.SERVER_URL + configData.checkAff,
            {
                affCode: affiliCode
            })
                .then(function (response) {
                    if (response.data.error === 0) {
                        localStorage.setItem("aff", affiliCode);
                        // localStorage.removeItem("tribe");
                        localStorage.setItem("pageType", "extracard");
                        navigate('/payment');
                    }else{
                        setErrorAffMessage(response.data.message);
                    }
            })
        }
    }
    useEffect(() => {
        document.body.style.overflow = 'unset';
    },[])
    
    const [dataCard] = useState(
        [
            {
                id: 1, 
                img: img1,
                title: '3D Digital Artwork',
                price : '2.26 ETH',
                avt: avt,
                name: 'Leslie Alexander',
                tag: '@leslie754'
            },
            {
                id: 2, 
                img: img2,
                title: '3D Digital Artwork',
                price : '2.26 ETH',
                avt: avt,
                name: 'Leslie Alexander',
                tag: '@leslie754',
                class: 'sm'
            },
        ]
    )
    return (
        <section className="about s3">
           <div className="shape"></div>
        <div className="shape right s3"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="block-text pd-0" style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                            {/* <h6 className="sub-heading"><span></span></h6> */}
                                <h3 className="heading">Welcome !</h3>
                                <h6 className="mb-17">If you have an active code, you can pre-register for ExtraCard.
                                To pre-register, we will ask you to enter your mobile number, your email address, and to pay the 149€ corresponding to one year of Premium subscription to ExtraCard.</h6>
                                <h6 style={{textDecoration: 'underline'}}>Why pre-register before the launch?</h6>
                                <h6 className="mb-26">To ensure that you are among the first 1,000 registrants, and thus benefit from an NFT with higher royalties than the next 9,000 registrants will be entitled to.</h6>
                                <div style={{width: "100%", textAlign: "center"}}>
                                    <img src={nft} alt='' className="goldenticket-img"/>
                                </div>
                                <h6 style={{textDecoration: 'underline', marginTop: 20}}>Since mid-December :</h6>
                                <ul className='cardList'>
                                    <li style={{marginTop:10}}><h6>iPhone and Android apps will be available, and only pre-registrants will be able to activate them (provided they are European residents)</h6></li>
                                    <li style={{marginTop:10}}><h6>you will be able to register your wallet and claim your royalty bearing NFT</h6></li>
                                    <li style={{marginTop:10}}><h6>you can start playing on ExtraTribes.com to collect Rewards Points and earn extra NFTs in the gift shop.</h6></li>
                                </ul>
                                <h6 style={{marginTop: '30px'}}>Welcome</h6>
                                <h6 style={{marginBottom: '30px'}}>The Oracle</h6>

                                <div className='extracardInputs'>
                                    <div className='left'>
                                        <input className='codeInput' onChange={(e)=> {setTribCode(e.target.value)}} type="text" placeholder='Enter Your Tribecode...'/>
                                        <h6 style={{color: 'red', height:25}}>{errorTribeMessage}</h6>
                                        <input className='codeInput' onChange={(e)=> {setAffiliCode(e.target.value)}} type="text" placeholder='Enter your Affiliate Code...'/>
                                        <h6 style={{color: 'red', height:25}}>{errorAffMessage}</h6>
                                        <button onClick={handleSubmitCode} disabled className="validateButton"><span>Validate  <ArrowRight style={{width:18}}/></span></button>
                                    </div>
                                    <h6 style={{color: 'red', height:25, fontSize: 24,}}>ALPHA closed and running. BETA will open soon</h6>
                                </div>

                               
                                

                        </div>
                        

                    </div>
                    <div className="col-xl-6 col-md-12" >
                        <div className="about__right" data-aos="fade-left" data-aos-duration="2000">

                            {
                                dataCard.map(idx => (
                                    <div key={idx.id} className={`card-box ${idx.class}`}>
                                        <div className="content">
                                            <div className="image" style={{marginBottom: 0}}>
                                                <img src={idx.img} alt="Cyfonii" />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                    
                </div>
                <div>
                    <Collection data={categoryImage} />
                </div>
            </div>
        </section>
    );
}

export default Main;