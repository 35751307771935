import React, { useState } from "react";
import claim from "../../assets/images/claim.png";
import warning from "../../assets/images/warning.png";
import "./styles.scss";
import axios from "axios";
import configData from "../../config.json";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import closeFill from "@iconify-icons/eva/close-fill";
import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import { useMoralis } from "react-moralis";
import Abi from "./abi.json";
import GoogleAuthForm from "../GoogleAuthForm/GoogleAuthForm";
import { Form, FormikProvider, useFormik } from "formik";
import useAuth from "../../hooks/useAuth";
import LinkIcon from '@mui/icons-material/Link';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { getMyNumbers } from '../../redux/slices/game';
import { useSnackbar } from "notistack";
import { IconButton } from "@mui/material";
function roundDown(number, decimals) {
  decimals = decimals || 0;
  return Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export default function ClaimRekk() {
    const [number, setNumber] = useState(500);
    const [wallet, setWallet] = useState("");
    const [istransfer, setIsTransfer] = useState(false);
    const {Moralis, enableWeb3, authenticate, isAuthenticated,account, logout, user, web3 } = useMoralis();
    const [isLoading, setLoading] = useState(false);
   
    const { userEarthium } = useAuth();
    const isMountedRef = useIsMountedRef();
    let claimValue = React.createRef();
    let claimWallet = React.createRef();
    const bckAddress = localStorage.getItem("bckAddress");
    const { userXtrb } = useAuth();  
    const dispatch = useDispatch();
  
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const validateWallet = (event) => {
   
      const value = event.target.value;
   
      //const setValue = temp <= 1  ? temp : number ;
      setWallet(value);
    };
    const validateNumber = (event) => {
      var temp = 0;
      const value = event.target.value;
      temp = value;
      if (value < 0) {
        temp = value * -1;
      }
      temp = roundDown(temp);
      //const setValue = value <= 500 && value >=10 ? value : number ;
      const setValue = temp <= 5000 ? temp : number;
      //const setValue = temp <= 1  ? temp : number ;
      setNumber(setValue);
    };
    async function moralisClaim() {
      
      web3.setProvider(new web3.providers.HttpProvider());
      console.log("provider:", web3.currentProvider);
      try {
        if (account) {
          const tokenContract = new web3.eth.Contract(
            Abi,
            "0x89637Fee24c59726fff4a8DfcF32Ef41BD404eaE"
          );
          console.log(tokenContract.methods);
  
          const receipt = await tokenContract.methods
            .mint(user.get("ethAddress"), 10)
            .send({ from: user.get("ethAddress") });
          console.log("minted, tokenID: ", receipt);
          console.log("events:: ", JSON.stringify(receipt.events));
          console.log("transfer :: ", JSON.stringify(receipt.events.Transfer));
          // events seems to be empty here:
          //return receipt.events.Transfer.returnValues.tokenId;
        }
      } catch (ex) {
        console.log(ex);
      }
    }
    const onValidCodeSucces = async (message) => {
      try {
        setLoading(true);
  
        window.localStorage.setItem("bckAddress", values.address);
        const reponse = await axios.post(
          configData.SERVER_URL + configData.straton,
          {
            blockchain: "xxx", // erc PROD, TEST with xxx
            amount: number,
            address: account,
            uuid: values.uuid,
          }
        );
        if (reponse.data.error === 0) {
          enqueueSnackbar("claim success", {
            variant: "success",
            action: (key) => (
              <IconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </IconButton>
            ),
          });
          dispatch(getMyNumbers());
        } else {
          enqueueSnackbar(reponse.data.message, {
            variant: "error",
            action: (key) => (
              <IconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </IconButton>
            ),
          });
        }
    
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.code });
          setSubmitting(false);
        }
        let msg = "";
        if (error.response && error.response.data) {
          msg = error.response.data.message;
        } else {
          msg = "Error 500";
        }
        enqueueSnackbar(msg, {
          variant: "error",
          action: (key) => (
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </IconButton>
          ),
        });
      }
    };

    const formik = useFormik({
      enablereinitialize: true,
      initialValues: {
        uuid: userXtrb.id,
        amount: number,
        address:  wallet,
      },
      onSubmit: async (values, { setErrors, setSubmitting }) => {
        try {
          //setLoading(true);
          onValidCodeSucces()
          window.localStorage.setItem("bckAddress", values.address);
        } catch (error) {
          console.error(error);
        }
      },
    });
  
    const onFailed = (error) => {
      enqueueSnackbar(error, {
        variant: "error",
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        ),
      });
    };
    const {
      values,
      handleSubmit,
      getFieldProps,
      setErrors,
      setSubmitting,
      resetForm,
    } = formik;

    async function moralisConnect() {
      try {
        const isMetaMaskInstalled = await Moralis.isMetaMaskInstalled();
  
        if (isMetaMaskInstalled) {
          //await authenticate({ signingMessage: 'Earthium Authentication' });
          await enableWeb3();
  
          localStorage.setItem('walletProvider', '');
        } else {
          await authenticate({
            provider: 'walletconnect',
            chainId: process.env.REACT_APP_REKK_CHAIN,
            mobileLinks: [
              'metamask',
              // "argent",
              'trust',
            ],
            signingMessage: 'Earthium Authentication',
          });
          let provider = 'walletconnect';
          localStorage.setItem('walletProvider', provider);
          await enableWeb3({ provider });
        }
        
        setIsTransfer(true);
      } catch (ex) {
        console.log(ex);
      }
    }
  return (
    <>
      <FormikProvider value={formik}>
        <Form
          style={{ width: "100%" }}
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <div className="claim-wrapper">
            <h5>CLAIM REKK </h5>
            <h6 style={{marginBottom: 20, marginTop:10}}>
              You can claim REKK from here. Please note, a small fee is
              applicable!
            </h6>
            <div className="top">
              <div className="left">
                <input
                  style={{width: '100%'}}
                  className="stripeInput"
                  ref={claimValue}
                  onChange={validateNumber}
                  placeholder="Amount"
                />
              </div>

            </div>
            
            {account ? (
              <div style={{display: 'flex', alignItems: 'center'}}>
                <img src={claim} alt="" style={{marginRight:20, marginBottom: 20}}/>
                <input
                  style={{width: '100%'}}
                  ref={claimWallet}
                  onChange={validateWallet}
                  className='stripeInput'
                  defaultValue={account}
                />
              </div>
            ) : (
              <div style={{display: 'flex', alignItems: 'center'}}>
                <img src={claim} alt="" style={{marginRight:20, marginBottom: 20}}/>
                <input
                 style={{width: '100%'}}
                 className='stripeInput'
                  ref={claimWallet}
                  onChange={validateWallet}
                  placeholder="Wallet Address"
                />
              </div>
            )}

            <div style={{display: 'flex', alignItems: 'center', marginBottom: 20, marginTop:5}}>
              <img src={warning} alt="" style={{marginRight:10}}/>
              <h6 style={{fontSize: 12}}>Fees Provision - 0.3$</h6>
            </div>

            {account ? (
			  <div style={{display: 'flex', marginBottom: 15}}><h6 style={{marginRight:20}}>Connected to {account.slice(0, 10)}.....{account.slice(35, 50)}</h6> <LinkIcon style={{marginTop: -3}}/></div>
						) : (
              <div style={{display: 'flex', marginBottom: 15, cursor:'pointer'}} onClick={moralisConnect}><h6 style={{marginRight:20}}>Not Connected</h6><LinkIcon style={{marginTop: -3}}/></div>
						)}
           
             {account ? 
             <button type="submit" className="action-btn" disabled={isLoading}>
                <span style={{width: 150, textAlign: 'center'}}>{isLoading ? <CircularProgress size={15}/> : <>{"CLAIM REKK"}</>}</span>
              </button> : 
              <button type="submit" className="action-btn" disabled>
                <span>Not Connected</span>
              </button>
              }
         
          </div>
        </Form>
      </FormikProvider>
    
    </>
  )
}
